import ProfileForm from '@/components/forms/Profile.form';
import { useAppDispatch } from '@/hooks/app.hook';
import { useAuth } from '@/hooks/auth.hook';
import { User } from '@/interfaces/user.interface';
import { setUser } from '@/redux/actions/auth.actions';
import APIService from '@/services/API';
import { Button, Card, Col, Divider, Form, message, Row } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ProfileScreen = () => {
  const [form] = Form.useForm();
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue(user);
  }, [user]);
  const onSubmit = async (values: User) => {
    // eslint-disable-next-line no-debugger
    debugger;
    const response = await APIService.updateUser(user._id, values);
    if (response.ok) {
      dispatch(setUser(response.data as User));
      message.success(t('general.updated'));
    } else {
      message.error(response.data?.message);
    }
  };
  return (
    <Card
      style={{
        height: '80vh',
      }}
    >
      <Row justify="end" gutter={[20, 20]}>
        <Col>
          <Button type="primary" onClick={() => form.submit()}>
            {t('general.save')}
          </Button>
        </Col>
      </Row>
      <Divider></Divider>
      <ProfileForm formRef={form} onSubmit={onSubmit} />
    </Card>
  );
};

export default ProfileScreen;
