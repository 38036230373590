import React, { useState } from 'react';
import { Button, Image, Modal } from 'antd';
import FilesWrapper from '../files/FilesWrapper';

import { useTranslation } from 'react-i18next';

export interface PreviewComponentProps {
  src: string;
}
export interface ImagePickerProps {
  value?: string;
  showRemove?: boolean;
  TriggerComponent?: React.ReactNode;
  PreviewComponent?: React.FC<PreviewComponentProps>;
  onChange?: (value: string | undefined) => void;
}

const ImagePicker: React.FC<ImagePickerProps> = ({
  value,
  onChange,
  TriggerComponent,
  PreviewComponent,
  showRemove = false,
}) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);

  return (
    <>
      <Modal open={modal} onCancel={() => setModal(false)} centered footer={null}>
        <div className="dynamic-form">
          <FilesWrapper
            initialLayout="list"
            form
            formProps={{
              value: value || '',
              dataIndex: 'path',
              onChange: (value) => {
                onChange?.(value);
                setModal(false);
              },
            }}
          />
        </div>
      </Modal>
      {!value ? (
        TriggerComponent ? (
          <button style={{ all: 'unset' }} onClick={() => setModal(true)}>
            {TriggerComponent}
          </button>
        ) : (
          <Button type="primary" onClick={() => setModal(true)}>
            {t('general.selectImage')}
          </Button>
        )
      ) : (
        <>
          {PreviewComponent ? (
            <button style={{ all: 'unset' }} onClick={() => setModal(true)}>
              <PreviewComponent src={value} />
            </button>
          ) : (
            <Image
              src={value}
              preview={false}
              style={{
                cursor: 'pointer',
                maxHeight: '200px',
                maxWidth: '100%',
              }}
              onClick={() => setModal(true)}
            />
          )}
          {showRemove && (
            <Button
              style={{ marginTop: 10, width: '100%' }}
              type="default"
              onClick={() => {
                onChange?.(undefined);
              }}
            >
              {t('general.removeImage')} 2222
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default ImagePicker;
