import React from 'react';
import { Avatar, Col, Form, FormInstance, Input, Row } from 'antd';
// Hooks
import { useTranslation } from 'react-i18next';
// Interfaces
import { User, UserCreation } from '@/interfaces/user.interface';
import { validateSlugField } from '@/utils/validations';
import ImagePicker from '../ui/ImagePicker';
import { UserOutlined } from '@ant-design/icons';

export interface ProfileFormProps {
  formRef: FormInstance<User | UserCreation>;
  edit?: boolean;
  onSubmit?: (values: User | UserCreation) => Promise<void>;
}
const ProfileForm: React.FC<ProfileFormProps> = ({ formRef, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <Form name="profile" form={formRef} onFinish={onSubmit} layout="vertical">
      <Row gutter={[10, 10]}>
        <Col
          span={12}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Form.Item
            name="profile"
            valuePropName="src"
            trigger="onSelect"
            getValueFromEvent={(e) => e}
          >
            <ImagePicker
              TriggerComponent={
                <Avatar
                  size={300}
                  icon={<UserOutlined />}
                  style={{ cursor: 'pointer' }}
                />
              }
              PreviewComponent={({ src }) => (
                <Avatar size={300} src={src} style={{ cursor: 'pointer' }} />
              )}
              value={formRef.getFieldValue('profile')}
              onChange={(value) => {
                // eslint-disable-next-line no-debugger
                debugger;
                console.log('On change trigger: ', value);
                // formRef.setFieldValue('profile', value);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Form.Item
                label={t('users.fullName')}
                name="full_name"
                rules={[
                  { required: true, type: 'string', min: 5, max: 50, whitespace: true },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={t('users.email')}
                name="email"
                rules={[{ required: true, type: 'email', whitespace: true }]}
              >
                <Input type="email" disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('users.username')}
                name="username"
                rules={[
                  {
                    max: 30,
                    min: 4,
                    required: true,
                    type: 'string',
                    whitespace: true,
                  },
                  {
                    validator: validateSlugField,
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default ProfileForm;
